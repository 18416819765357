<script setup lang="ts">
import {
  ArrowRightIcon,
  ChevronLeftIcon,
} from 'lucide-vue-next';
import type { NavigationItemQuery } from '#gql';

withDefaults(defineProps<{
  items?: NavigationItemQuery['navigationItem']['groups'];
  parent?: string | null;
}>(), { items: [],parent: '', })

const {
  setSecondLevel,
  setThirdLevel,
} = useBurgerNavigation();
</script>

<template>
  <div class="burger-menu-second-level relative -right-full mb-4 flex flex-col bg-white">
    <span
      class="flex cursor-pointer items-center bg-grey-light p-4"
      @click="setSecondLevel(-1)"
    >
      <ChevronLeftIcon class="mr-4 size-4 text-dark" />
      <span
        class="text-sm font-semibold text-dark"
        v-text="parent"
      />
    </span>
    <span
      v-for="(item, index) in items"
      :key="item.id"
      class="flex w-full cursor-pointer items-center justify-between border-b border-b-grey p-4"
      @click="setThirdLevel(index)"
    >
      <span
        class="text-sm"
        v-text="item.title"
      />
      <ArrowRightIcon
        v-if="item.columns"
        class="size-4 text-dark"
      />
    </span>
  </div>
</template>
